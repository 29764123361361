import { Routes, Route } from "react-router-dom";
import MobileNavbar from "./components/MobileNavbar/MobileNavbar";
import Navbar from "./components/Navbar/Navbar";
import Main from "./pages/Main/Main";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import PP from "./pages/PP/PP";
import TOS from "./pages/TOS/TOS";
import ParticlesBackground from "./components/ParticlesBackground";
import Particles from "react-tsparticles";

function App() {
	const particlesInit = useCallback(async (engine) => {
		console.log(engine);
		// you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
		// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
		// starting from v2 you can add only the features you need reducing the bundle size
		await loadFull(engine);
	}, []);

	const particlesLoaded = useCallback(async (container) => {
		await console.log(container);
	}, []);
	return (
		<div className="App">
			<Navbar />
			<MobileNavbar />

			<Routes>
				<Route exact path="/termsOfServices" element={<TOS />} />
				<Route exact path="/privacyPolicy" element={<PP />} />
				<Route path="/" element={<Main />} />
			</Routes>
			<Particles
				id="tsparticles"
				init={particlesInit}
				loaded={particlesLoaded}
				options={{
					background: {
						color: {
							value: "none",
						},
					},
					fpsLimit: 120,
					interactivity: {
						events: {
							onClick: {
								enable: true,
								mode: "push",
							},
							onHover: {
								enable: true,
								mode: "repulse",
							},
							resize: true,
						},
						modes: {
							push: {
								quantity: 4,
							},
							repulse: {
								distance: 200,
								duration: 0.4,
							},
						},
					},
					particles: {
						color: {
							value: "#231f28",
						},
						links: {
							color: "#ffffff",
							distance: 150,
							enable: true,
							opacity: 0.1,
							width: 1,
						},
						collisions: {
							enable: true,
						},
						move: {
							directions: "none",
							enable: true,
							outModes: {
								default: "bounce",
							},
							random: false,
							speed: 1,
							straight: false,
						},
						number: {
							density: {
								enable: true,
								area: 800,
							},
							value: 80,
						},
						opacity: {
							value: 0.1,
						},
						shape: {
							type: "circle",
						},
						size: {
							value: { min: 1, max: 5 },
						},
					},
					detectRetina: true,
				}}
			/>
		</div>
	);
}

export default App;
