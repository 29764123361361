import React, {useState, useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { HiChevronDown } from "react-icons/hi";
import './FAQ.scss'

export default function FAQ() {
    useEffect(() => {
        AOS.init();
      }, []);

    const [faq1Clicked, setFaq1Clicked] = useState(false);
    const [faq2Clicked, setFaq2Clicked] = useState(false);
    const [faq3Clicked, setFaq3Clicked] = useState(false);
    const [faq4Clicked, setFaq4Clicked] = useState(false);
    const [faq5Clicked, setFaq5Clicked] = useState(false);

    useEffect(() => {
        if(faq1Clicked === true){
            setFaq2Clicked(false)
            setFaq3Clicked(false)
            setFaq4Clicked(false)
            setFaq5Clicked(false)
        }
      }, [faq1Clicked]);

      useEffect(() => {
        if(faq2Clicked === true){
            setFaq1Clicked(false)
            setFaq3Clicked(false)
            setFaq4Clicked(false)
            setFaq5Clicked(false)
        }
      }, [faq2Clicked]);

      useEffect(() => {
        if(faq3Clicked === true){
            setFaq1Clicked(false)
            setFaq2Clicked(false)
            setFaq4Clicked(false)
            setFaq5Clicked(false)
        }
      }, [faq3Clicked]);

      useEffect(() => {
        if(faq4Clicked === true){
            setFaq1Clicked(false)
            setFaq2Clicked(false)
            setFaq3Clicked(false)
            setFaq5Clicked(false)
        }
      }, [faq4Clicked]);

      useEffect(() => {
        if(faq5Clicked === true){
            setFaq1Clicked(false)
            setFaq2Clicked(false)
            setFaq3Clicked(false)
            setFaq4Clicked(false)
        }
      }, [faq5Clicked]);

  return (
		<div className="faq-wrapper" id="faq">
			<div className="faq-container" data-aos="fade-up">
				<h5>Frequently Asked Questions</h5>
				<h2>Get answers to the most commonly asked questions.</h2>

				<div
					onClick={() => setFaq1Clicked(!faq1Clicked)}
					data-aos="fade-up"
					className="faqcard-wrapper"
				>
					<div className="faqs">
						<div>
							<h6>How do I receive my purchase?</h6>
						</div>
						{faq1Clicked === true ? (
							<p>
								Your order will be automatically delivered via the dashboard
							</p>
						) : (
							""
						)}
					</div>
					<div className="selector-container">
						<HiChevronDown
							className={faq1Clicked === false ? "arrow" : "arrow clicked"}
						/>
					</div>
				</div>

				<div
					onClick={() => setFaq2Clicked(!faq2Clicked)}
					data-aos="fade-up"
					data-aos-anchor-placement="top-bottom"
					className="faqcard-wrapper"
				>
					<div className="faqs">
						<div>
							<h6>Do I need to authenticate using my IP address</h6>
						</div>
						{faq2Clicked === true ? (
							<p>
								All our proxies are authenticated using user name and password
								for your convenience
							</p>
						) : (
							""
						)}
					</div>
					<div className="selector-container">
						<HiChevronDown
							className={faq2Clicked === false ? "arrow" : "arrow clicked"}
						/>
					</div>
				</div>

				<div
					onClick={() => setFaq3Clicked(!faq3Clicked)}
					data-aos="fade-up"
					data-aos-anchor-placement="top-bottom"
					className="faqcard-wrapper"
				>
					<div className="faqs">
						<div>
							<h6>How can I contact support</h6>
						</div>
						{faq3Clicked === true ? (
							<p>Please join our discord support server for more help</p>
						) : (
							""
						)}
					</div>
					<div className="selector-container">
						<HiChevronDown
							className={faq3Clicked === false ? "arrow" : "arrow clicked"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
