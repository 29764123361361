import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import i1 from "../../images/g1.svg";
import i2 from "../../images/g2.svg";
import i3 from "../../images/g3.svg";
import i4 from "../../images/g4.svg";
import i5 from "../../images/g5.svg";
import i6 from "../../images/g6.svg";

import d1 from "../../images/b1.svg";
import d2 from "../../images/b2.svg";
import d3 from "../../images/b3.svg";
import d4 from "../../images/b4.svg";
import d5 from "../../images/b5.svg";
import d6 from "../../images/b6.svg";
import "./styles.scss";

export default function Features() {
	const [open, setOpen] = useState("");

	const unhover = () => {
		setOpen("");
	};

	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	return (
		<div className="features-wrapper" id="features">
			<div className="features-container">
				<h2>FEATURES</h2>
				<div>
					<div
						data-aos="fade-up"
						data-aos-anchor-placement="center-bottom"
						onMouseOver={() => setOpen("one")}
						onMouseOut={unhover}
					>
						<div>
							<img src={open === "one" ? d1 : i1} alt="" />
							<h5>Global Network</h5>
							<p>
								Our IP address service offers global coverage, with fine-tuned
								pools for smoother performance and competitive pricing. Whether
								you need an IP address from any country or city, we have you
								covered.
							</p>
						</div>
					</div>

					<div
						data-aos="fade-up"
						data-aos-anchor-placement="center-bottom"
						onMouseOver={() => setOpen("two")}
						onMouseOut={unhover}
					>
						<div>
							<img src={open === "two" ? d2 : i2} alt="" />
							<h5>Site Security</h5>
							<p>
								With over a decade of experience in site security, we guarantee
								undetected access through any type of restrictions. Our
								meticulously maintained pools provide a smooth handshake with
								anti-bot technologies like Kasada and Akamai.
							</p>
						</div>
					</div>

					<div
						data-aos="fade-up"
						data-aos-anchor-placement="center-bottom"
						onMouseOver={() => setOpen("three")}
						onMouseOut={unhover}
					>
						<div>
							<img src={open === "three" ? d3 : i3} alt="" />
							<h5>The fastest speeds Available</h5>
							<p>
								Fastest Speeds Available should also say in text: Our optimized
								networks are hosted in specific locations, enabling our
								customers to outpace the competition, even in time-sensitive
								scenarios. Our Residental and Datacenter services are hosted
								globally, providing fast connections for customers anywhere in
								the world.
							</p>
						</div>
					</div>

					<div
						data-aos="fade-up"
						data-aos-anchor-placement="center-bottom"
						onMouseOver={() => setOpen("four")}
						onMouseOut={unhover}
					>
						<div>
							<img src={open === "four" ? d4 : i4} alt="" />
							<h5>Online 24/7 Guarantee</h5>
							<p>
								Our networks maintain 99.9% reliability, instantly offloading
								traffic in the event of an outage. Our automated administrative
								systems ensure connection reliablity during any type of classes.
							</p>
						</div>
					</div>

					<div
						data-aos="fade-up"
						data-aos-anchor-placement="center-bottom"
						onMouseOver={() => setOpen("five")}
						onMouseOut={unhover}
					>
						<div>
							<img src={open === "five" ? d5 : i5} alt="" />
							<h5>Instant Delivery</h5>
							<p>
								Our dashboard delivery system is entirely automated, ensuring as
								soon as you place your order your delivery happens immediately.
								We understand how crucial it is to have instant access to the
								resources we provide, as well as simple management of all of our
								products that you purchase in one organized place.
							</p>
						</div>
					</div>

					<div
						data-aos="fade-up"
						data-aos-anchor-placement="center-bottom"
						onMouseOver={() => setOpen("six")}
						onMouseOut={unhover}
					>
						<div>
							<img src={open === "six" ? d6 : i6} alt="" />
							<h5>24/7 Customer Service</h5>
							<p>
								We are online, all the time, like our services. We offer support
								directly in our Discord server, as well as via email with near
								instant response time. Our team is here to assist with any
								problems you may have,valuing our customers time above all else.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
