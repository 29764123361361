import React, { useEffect } from "react";
import AOS from "aos";
import "./styles.scss";
import logo from "../../images/site_logo.png";
import tw from "../../images/twitter.svg";
import ds from "../../images/discord.svg";
import { Link } from "react-router-dom";

export default function Footer() {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	return (
		<div className="footer-wrapper">
			<img src={logo} alt="" className="logo" />
			<div className="footer-content" id="contact">
				<p>Copyright Level Proxies All Rights Reserved</p>
				<div>
					<Link to="/privacyPolicy" style={{ marginRight: "20px" }}>
						Privacy Policy
					</Link>
					<Link to="/termsOfServices" style={{ marginRight: "20px" }}>
						Terms and Conditions
					</Link>
					<a
						href="https://twitter.com/LevelProxies"
						style={{ marginRight: "20px" }}
					>
						<img src={tw} alt="" />
					</a>
					<a href="https://discord.com/invite/HvxyChJxTS">
						<img src={ds} alt="" />
					</a>
				</div>
			</div>
		</div>
	);
}
