import React, { useEffect } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";
import homet from "../../images/homet.png";
import "./styles.scss";
import { HashLink } from "react-router-hash-link";

export default function Home() {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	return (
		<div className="home-wrapper" id="home" data-aos="zoom-in">
			<div className="home-container">
				<img src={homet} alt="" />
				<h1>LEVEL PROXIES</h1>

				<p>
					The most reliable proxies available on the market. Optimized for
					success.
				</p>
				<HashLink smooth to="/#pricing">
					<button>Buy Now</button>
				</HashLink>
			</div>
		</div>
	);
}
