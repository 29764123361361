import React, { useState } from "react";
import PlanCard from "./PlanCard/PlanCard";
import "./styles.scss";

export default function Plans() {
	const [clicked, setClicked] = useState("isp");
	return (
		<div className="plans-wrapper" id="pricing">
			<div className="plans-container">
				<h4>Product Plans</h4>

				<div className="tabs-container">
					<button
						onClick={() => setClicked("isp")}
						className={clicked === "isp" ? "selected" : ""}
					>
						ISPs
					</button>
					<button
						className={clicked === "subnets" ? "selected" : ""}
						onClick={() => setClicked("subnets")}
					>
						Subnets
					</button>
					<button>
						<a
							href="https://bitvps.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Services
						</a>
					</button>
				</div>
				{clicked === "isp" && (
					<div className="content">
						{[
							{
								heading: "Monthly",
								title: "Level Monthly ISP",

								l1: "Instant Delivery",
								l2: "Optimized for: Event and Retail",
								l3: "Ashburn, VA",
								l4: "10 GbE Uplink",
								opt1: "10",
								opt2: "20",
								opt3: "25",
								opt4: "50",
								opt5: "50",
								opt6: "100",
								opt7: "100",
								opt8: "200",
							},
						].map((item, i) => (
							<PlanCard key={i} data={item} />
						))}
					</div>
				)}

				{clicked === "subnets" && (
					<div className="content">
						{[
							{
								heading: "Monthly",
								title: "Level Monthly Subnets",

								l1: "Optimized for: Event and Retail",
								l2: "Ashburn, VA",
								l3: "10 GbE Uplink",
								l4: "Private subnets",

								opt1: "64",
								opt2: "128",
								opt3: "128",
								opt4: "256",
							},
						].map((item, i) => (
							<PlanCard key={i} data={item} />
						))}
					</div>
				)}
			</div>
		</div>
	);
}
